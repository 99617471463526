import {
  AddressType,
  Client as GoogleMapsClient,
} from "@googlemaps/google-maps-services-js";

import serverConfig from "config/server";
import { Coordinates } from "custom-types/Coordinates";
import { calculateRadiusInMiles } from "utils/finder/mapUtils";
import logError from "utils/logError";
import initializeGoogleMapsGeocoder from "utils/maps/initializeGoogleMapsGeocoder";
import slugify from "utils/slugifyURLComponent";

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type CityInfo = {
  coordinates?: Coordinates;
  boundedRadius?: number;
  countryCode?: string;
  city?: string;
  state?: string; // 'NJ'
  stateLong?: string; // 'New Jersey'
  citySlug?: string; // 'st-petersburg'
  stateSlug?: string; // 'new-jersey'
};

const serverApiKey = serverConfig.googleMapsServerApiKey;

export const getCityStateGeocode = async ({
  city,
  state,
  minimumRadius = 20,
}: {
  city?: string;
  state?: string;
  minimumRadius?: number;
}): Promise<CityInfo> => {
  if (typeof window !== "undefined") {
    return getCityStateGeocodeClientSide({
      city,
      minimumRadius,
      state,
    });
  } else {
    return getCityStateGeocodeServerSide({
      city,
      minimumRadius,
      state,
    });
  }
};

export const getCityStateGeocodeClientSide = async ({
  city,
  state,
  minimumRadius = 20,
}: {
  city?: string;
  state?: string;
  minimumRadius?: number;
}): Promise<CityInfo> => {
  const geocoder = await initializeGoogleMapsGeocoder();

  return new Promise((resolve) => {
    geocoder?.geocode(
      {
        componentRestrictions: {
          administrativeArea: state,
          locality: city,
        },
      },
      (results, status) => {
        if (status === "OK" && results?.length) {
          const {
            geometry: { bounds, location },
            address_components,
          } = results[0];

          const countryCode = getCountryCode(address_components);

          const center_lat = location.lat();
          const center_lon = location.lng();

          const dist =
            bounds &&
            calculateRadiusInMiles({
              center_lat,
              center_lon,
              lat: bounds.getNorthEast().lat(),
              lon: bounds.getNorthEast().lng(),
            });

          let boundedRadius = dist;

          if (dist !== undefined && dist < minimumRadius) {
            boundedRadius = minimumRadius;
          }

          resolve({
            boundedRadius,
            coordinates: {
              lat: center_lat,
              lon: center_lon,
            },
            countryCode,
          });
        } else {
          console.warn(
            `Geocoding failed for state '${state}' and city '${city}'`,
          );
          resolve({});
        }
      },
    );
  });
};

export const getCityStateGeocodeServerSide = async ({
  city,
  state,
  minimumRadius = 20,
}: {
  city?: string;
  minimumRadius?: number;
  state?: string;
}): Promise<CityInfo> => {
  const geocoder = new GoogleMapsClient();

  try {
    const res = await geocoder.geocode({
      params: {
        components: {
          administrative_area: state,
          locality: city,
        },
        key: serverApiKey,
      },
    });

    if (res.data?.status === "OK" && res.data.results?.length) {
      const {
        geometry: { bounds, location },
        address_components,
      } = res.data.results[0];

      const center_lat = location.lat;
      const center_lon = location.lng;

      const dist = bounds
        ? calculateRadiusInMiles({
            center_lat,
            center_lon,
            lat: bounds.northeast.lat,
            lon: bounds.northeast.lng,
          })
        : undefined;

      let boundedRadius = dist;

      if (dist !== undefined && dist < minimumRadius) {
        boundedRadius = minimumRadius;
      }

      const { state, stateLong, stateSlug } = getState(address_components);
      const { city, citySlug } = getCity(address_components);
      return {
        boundedRadius,
        city,
        citySlug,
        coordinates: {
          lat: center_lat,
          lon: center_lon,
        },
        countryCode: getCountryCode(address_components),
        state,
        stateLong,
        stateSlug,
      };
    } else {
      console.warn(`Geocoding failed for state '${state}' and city '${city}'`);
      return {};
    }
  } catch (e) {
    logError(e.response?.data?.error_message || e.message, {
      functionName: "getCityStateGeocodeServerSide",
      service: "google-maps",
      statusCode: e.statusCode || e.response?.data?.status,
    });
    return {};
  }
};

const getCountryCode = (addressComponents: AddressComponent[]): string => {
  const countries = addressComponents.filter((c) =>
    c.types.includes("country" as AddressType),
  );
  return countries.length ? countries[0].short_name : "US";
};

const getCity = (
  addressComponents: AddressComponent[],
): {
  city?: string;
  citySlug?: string;
} => {
  const cities = addressComponents.filter((c) =>
    c.types.includes("locality" as AddressType),
  );
  return cities.length
    ? {
        city: cities[0].long_name,
        citySlug: slugify(cities[0].long_name),
      }
    : {};
};

const getState = (
  addressComponents: AddressComponent[],
): {
  state?: string;
  stateLong?: string;
  stateSlug?: string;
} => {
  const states = addressComponents.filter((c) =>
    c.types.includes("administrative_area_level_1" as AddressType),
  );
  return states.length
    ? {
        state: states[0].short_name,
        stateLong: states[0].long_name,
        stateSlug: slugify(states[0].long_name),
      }
    : {};
};

export default getCityStateGeocode;
