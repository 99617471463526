import kebabCase from "lodash/kebabCase";

import publicConfig from "config/public";
import { Location } from "custom-types/Location";
import { setClientSideCookie } from "utils/cookies";
import { hasFullAddress } from "utils/hasFullAddress";

import { getLocationSlug as getLocationSlugUtil } from "./getLocationSlug";

const { cookieDomainCa, cookieDomain } = publicConfig;

export interface CityState {
  state?: string;
  city?: string;
  stateCode?: string;
  zip?: string;
  slug?: string;
}

// Reuse existing utility, but also add support for normalized `statCode`
export const getLocationSlug = ({
  city,
  state,
  stateCode,
  state_code,
}: CityState & { state_code?: string }) =>
  getLocationSlugUtil({
    city,
    state,
    state_code: (stateCode || state_code) as string,
  });

export const getLocationLabel = ({
  city,
  stateCode,
  state,
}: Partial<CityState>) => {
  if (!city) {
    if (!state) {
      return "";
    }
    return `${state}`;
  }
  return `${city}, ${stateCode ?? state ?? ""}`;
};

export const getUrlLocationPart = (part: string) =>
  kebabCase(part.toLowerCase());

export const buildRoutePath = (location: CityState) => {
  const { city, state, zip } = location;
  const stateString = state ? `/${getUrlLocationPart(state)}` : "";
  const cityString = city ? `/${getUrlLocationPart(city)}` : "";
  const zipString = zip ? `?zip=${zip}` : "";
  return `${stateString}${cityString}${zipString}`;
};

export const getLocationRoute = (
  path: string | null | undefined,
  location: CityState,
) => {
  if (!path) {
    return "";
  }
  if (path?.includes("finder")) {
    return `${changeOldRoutePath(path)}${
      location ? buildRoutePath(location) : ""
    }`;
  } else {
    return `${path}${buildRoutePath(location)}`;
  }
};

export const changeOldRoutePath = (oldPath: string) => {
  if (oldPath?.includes("/finder/cbd-stores")) {
    return "/cbd-stores";
  }
  if (oldPath?.includes("/finder/doctors")) {
    return "/medical-marijuana-doctors";
  }
  if (oldPath?.includes("/finder")) {
    return "/dispensaries";
  }
  return "/dispensaries";
};

// @ts-ignore (fix me please, do not replicate)
export const getFormattedLocation = (location) => {
  if ((location.sublocality || location.city) && location.state_code) {
    return [location.sublocality, location.city, location.state_code]
      .filter(Boolean)
      .join(", ");
  } else if (location.state && location.country_code) {
    return [location.state, location.country_code].filter(Boolean).join(", ");
  } else {
    return location.country;
  }
};

export const getCoordinate = (
  location: Location,
  coordinate: "latitude" | "longitude",
) => {
  if (coordinate === "latitude") {
    return (
      location?.latitude ||
      location?.coordinates?.lat ||
      location?.coordinates?.latitude
    );
  }
  return (
    location?.longitude ||
    location?.coordinates?.lon ||
    location?.coordinates?.longitude
  );
};

export const getLocationForCookie = (
  location: Location,
  isUserLocation = false,
) => {
  const sublocality = location.sublocality;
  const city = location.city;
  const state = location.state;
  const stateCode = location.state_code || location.stateCode;
  const zip = location.zip;
  const country = location.country;
  const countryCode = location.country_code || location.countryCode;

  const locationSlug = getLocationSlug({ city, state, stateCode });

  const latitude = getCoordinate(location, "latitude");
  const longitude = getCoordinate(location, "longitude");

  if (!latitude || !longitude) {
    throw new Error("Longitude and latitude are required");
  }

  const street = location.street;

  const formatted_location =
    (location.formatted_location || location.formattedLocation) ??
    getFormattedLocation({
      city,
      country,
      country_code: countryCode,
      state,
      state_code: stateCode,
      sublocality,
      zip,
    });

  // cookie format:
  return {
    city: city,
    coordinates: {
      latitude,
      longitude,
    },
    country: country,
    country_code: countryCode,
    formatted_location,
    isUserLocation: isUserLocation,
    slug: locationSlug.toLowerCase(),
    state: state,
    state_code: stateCode,
    ...(street && { street }),
    sublocality: sublocality,
    zip: zip,
  };
};

export const formatAddress = (address: Location) => {
  if (!hasFullAddress(address)) {
    return;
  }
  let addressParts = [
    `${address?.street?.number} ${address?.street?.name}`,
    address.city,
    address.state_code || address.stateCode || address.state,
    address.zip,
  ];

  if (address.country_code || address.countryCode) {
    addressParts = [
      ...addressParts,
      address.country_code || address.countryCode,
    ];
  }

  return addressParts.join(", ");
};

export const setLocationCookie = ({
  location,
  countryCode,
  isUserLocation = false,
  domain,
}: {
  location: Location;
  countryCode: string;
  isUserLocation?: boolean;
  domain?: string;
}) => {
  const cookieLocation = getLocationForCookie(location, isUserLocation);
  setClientSideCookie(
    "leafly-location",
    JSON.stringify({
      ...cookieLocation,
      formattedAddress: formatAddress(cookieLocation),
    }),
    domain ?? (countryCode === "CA" ? cookieDomainCa : cookieDomain),
  );
};

export const normalizeFinderLocation = (
  location?: Location,
): Location | undefined => {
  if (!location) {
    return;
  }

  const {
    coordinates,
    city,
    state,
    state_code,
    stateCode,
    formattedLocation,
    formatted_location,
    zip,
    country_code,
    countryCode,
    geoSlug,
    slug,
    latitude: _latitude,
    longitude: _longitude,
    place_id,
    placeId,
    radius,
    isUserLocation,
    street,
    sublocality,
    addressLine2,
  } = location;

  const reFormattedAddress = formatAddress(location);
  const { lat, lon, latitude, longitude } = coordinates ?? {};
  return {
    coordinates: {
      lat: Number(_latitude || lat || latitude) || latitude(),
      lon: Number(_longitude || lon || longitude) || longitude(),
    },
    isUserLocation: isUserLocation || false,
    ...(city && { city }),
    ...((countryCode || country_code) && {
      countryCode: countryCode || country_code,
    }),
    ...(reFormattedAddress && {
      formattedAddress: reFormattedAddress,
    }),
    ...((formattedLocation || formatted_location) && {
      formattedLocation: formatted_location || formattedLocation,
    }),
    ...((geoSlug || slug) && { geoSlug: slug || geoSlug }),
    ...((place_id || placeId) && { placeId: place_id || placeId }),
    ...(sublocality && { sublocality }),
    ...(radius && { radius }),
    ...(state && { state }),
    ...((stateCode || state_code) && { stateCode: stateCode || state_code }),
    ...(street && { street }),
    ...(zip && { zip }),
    ...(addressLine2 && { addressLine2 }),
  };
};
