import locations from "../../../constants/finder/locations";
import states, { stateData } from "../../../constants/states";
import { normalizeFinderLocation } from "../../locationUtils";

export default (city: string | undefined, state: string | undefined) => {
  const normalizedCity = (city || "").toLowerCase();
  const normalizedState = (state || "").toLowerCase();

  const foundState = states.find((st) => st.slug === normalizedState);
  const stateCode = foundState?.short;

  if (isCityCached(normalizedCity, stateCode)) {
    return normalizeFinderLocation(locations[`${normalizedCity}-${stateCode}`]);
  } else if (isStateCached(normalizedCity, normalizedState)) {
    return normalizeFinderLocation(
      stateData[normalizedState as keyof typeof stateData],
    );
  }
  return undefined;
};

function isCityCached(city: string | undefined, stateCode: string | undefined) {
  return city && stateCode && locations[`${city}-${stateCode}`];
}

function isStateCached(city: string | undefined, state: string | undefined) {
  return !city && state && stateData[state as keyof typeof stateData];
}
