import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "querystring";

import getCityStateGeocode from "api/requests/getCityStateGeocode";

import getGeolocation from "../../../api/requests/getGeolocation";
import { getUserLocationData } from "../../../api/requests/getUserLocation";
import { Location } from "../../../custom-types/Location";
import { getLocationSlug, normalizeFinderLocation } from "../../locationUtils";
import { validateZip } from "../../zipcodeValidation";
import getCachedLocation from "./getCachedLocation";

export default async (
  city: string | undefined,
  state: string | undefined,
  countryCode: string,
  context: GetServerSidePropsContext,
) => {
  const { query } = context;
  const locationParam = getLocationSlug({ city, state });
  let isLocationPage = !!state;
  let finderLocation: Location | undefined;

  const userLocation = await getUserLocation(countryCode, context);

  if (locationParam) {
    finderLocation = {
      ...(getCachedLocation(city, state) || (await geolocation(locationParam))),
    };

    if (!finderLocation.radius) {
      const { boundedRadius } = await getCityStateGeocode({ city, state });

      if (boundedRadius) {
        finderLocation.radius = Math.ceil(boundedRadius);
      }
    }
  }

  if (!finderLocation?.coordinates) {
    finderLocation = userLocation;
    isLocationPage = false;
  }

  if (
    !isLocationPage &&
    finderLocation &&
    !finderLocation.radius &&
    !finderLocation.city &&
    finderLocation.state
  ) {
    let radius;

    if (!radius) {
      const cachedLocation = getCachedLocation(
        finderLocation.city,
        finderLocation.state,
      );

      if (cachedLocation?.radius) {
        radius = cachedLocation.radius;
      }
    }

    if (!radius) {
      const cityStateGeocode = await getCityStateGeocode({
        city: finderLocation.city,
        state: finderLocation.state,
      });

      if (cityStateGeocode?.boundedRadius) {
        radius = cityStateGeocode.boundedRadius;
      }
    }

    if (radius) {
      finderLocation.radius = Math.ceil(radius);
    }
  }

  normalizeZip(finderLocation, query);

  return { finderLocation, isLocationPage };
};

const getUserLocation = async (
  countryCode: string,
  context: GetServerSidePropsContext,
) => normalizeFinderLocation(await getUserLocationData(countryCode, context));

function normalizeZip(
  finderLocation: Location | undefined,
  query: ParsedUrlQuery,
) {
  const { zip, state, city } = query;

  if (zip && finderLocation) {
    finderLocation.zip =
      typeof zip === "string" && validateZip(zip) ? zip : undefined;
  } else if ((state || city) && finderLocation) {
    finderLocation.zip = undefined;
  }
}

async function geolocation(locationParam: string) {
  const geolocationResponse = await getGeolocation({
    address: locationParam,
  });

  return geolocationResponse
    ? normalizeFinderLocation(geolocationResponse)
    : undefined;
}
